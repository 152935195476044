@import 'vars.scss';

.content-portfolio{
    position: relative;
    width: 100%; height: 100%; min-height: 100%;
    padding: 2.5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    background-color: rgba($color: white, $alpha: .25);
    border: 1px solid $color-main;
    box-shadow: 0px 0px 25px 0px $color-main;
    overflow: hidden;
    z-index: 1;

    &::before{
        content: '';
        position: absolute;
        width: 100%; height: 100%;
        top: 0px; left: 0px;
        background: linear-gradient(122deg, rgba(28,88,184,1) 0%, rgba(255,20,147,1) 56%, rgba(255,0,95,1) 100%);
        filter: blur(40px) brightness(.6);
        z-index: -1;
    }

    .portfolio-item{
        width: 30%; height: 30%;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $color-main;
        transition: box-shadow .15s ease-in;

        .wrapper{
            width: 100%; height: 100%;

            img{
                width: 100%; height: 100%;
            }

            .text-tech{
                width: 100%;
                margin-top: 2.5%;
                display: none;

                h1{
                    text-align: center;
                }

                .skills{
                    margin-top: 2.5%;
                    display: flex;
                    justify-content: space-around;

                    .skills-item{
                        position: relative;
                        width: fit-content; height: fit-content;
    
                        svg{
                            float: left;
                        }
    
                        .icon{
                            position: absolute;
                            width: 100%; height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
    
                            i{
                                font-size: 35px;
                            }
                        }
                    }
                }
            }
        }

        .text-desc{
            width: 47.5%; height: 100%;
            margin-left: 5%;
            display: none;

            h1{
                text-align: center;
            }
            
            p{
                margin-top: 1.5%;
            }
        }

        &.active{
            width: 100%; height: 100%;
            padding: 1.5%;

            .wrapper{
                width: 47.5%; height: 100%;

                img{
                    width: 100%; height: fit-content;
                    object-fit: contain;
                }

                .text-tech{
                    display: block;
                }
            }

            .text-desc{
                display: block;
            }
        }

        &.hidden{
            display: none;
        }

        &:hover{
            cursor: pointer;
            box-shadow: 0px 0px 25px 0px $color-main;
        }

        .overlay{
            position: absolute;
            width: 0%; height: 100%;
            top: 0px; left: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(122deg, rgba(28,88,184,1) 0%, rgba(255,20,147,1) 56%, rgba(255,0,95,1) 100%);
            cursor: default;
            transition: all .5s ease-in;

            p{
                transition: opacity .2s ease-in;
            }

            &.active{
                width: 100%;
                left: 0px;

                p{
                    opacity: 1;
                    visibility: visible;
                }
            }

            &.hidden{
                width: 0%;
                left: 100%;

                p{
                    opacity: 0;
                    visibility: hidden;
                }
            }

            &.disable-transition{
                transition: none;
            }
        }
    }
}

@media only screen and (max-width: 767px){
    .content-portfolio{
        height: fit-content;
        padding: 5%;
        overflow-y: scroll;

        .portfolio-item{
            width: 100%; height: fit-content;

            .wrapper{
                display: flex;
                flex-direction: column;

                .text-tech{
                    width: 100%;
                    margin-top: 5%;

                    .skills{
                        flex-wrap: wrap;
                    }
                }
            }

            .text-desc{
                width: 100%;
                padding: 1%;
                margin: 0px;
                margin-top: 5%;
            }

            &.active{
                .wrapper{
                    width: 100%;
                }
            }

            &:not(:first-child){
                margin-top: 3vh;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
    .content-portfolio{
        height: fit-content;
        padding: 5%;
        row-gap: 2vh;

        .portfolio-item{
            width: 100%; height: fit-content;

            .wrapper{
                min-height: 0vh;
                display: flex;
                flex-direction: column;

                .text-tech{
                    width: 100%;
                    margin-top: 5%;

                    .skills{
                        flex-wrap: wrap;
                    }
                }
            }

            .text-desc{
                width: 100%;
                margin-top: 5%;
            }

            &.active{
                .wrapper{
                    width: 100%;
                }
            }

            .overlay{
                p{
                    display: none;
                }
            }
        }
    }
}