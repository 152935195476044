@import 'vars.scss';

.content-contact{
    position: relative;
    width: 100%; height: 100%;
    padding: 2.5%;
    background-color: rgba($color: white, $alpha: .25);
    border: 1px solid $color-main;
    box-shadow: 0px 0px 25px 0px $color-main;
    overflow: hidden;
    z-index: 1;

    &::before{
        content: '';
        position: absolute;
        width: 100%; height: 100%;
        top: 0px; left: 0px;
        background: linear-gradient(122deg, rgba(28,88,184,1) 0%, rgba(255,20,147,1) 56%, rgba(255,0,95,1) 100%);
        filter: blur(40px) brightness(.6);
        z-index: -1;
    }

    .text{
        width: 75%;
        margin: auto;
        text-align: center;

        p{
            margin-top: 1vh;

            a{
                font-weight: bold;
                transition: color .15s ease-in;

                &:hover{
                    color: $color-main;
                }
            }

            span{
                font-weight: bold;
            }
        }
    }

    .form{
        width: 75%;
        margin: auto;
        margin-top: 2.5%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .form-item{
            width: 45%;
            display: flex;
            flex-direction: column;

            label{
                font-weight: bold;
            }

            input{
                margin-top: .75vh;
            }
        }

        .form-item-big{
            width: 100%;
            margin-top: 2vh;

            label{
                font-weight: bold;
            }

            textarea{
                margin-top: .75vh;
            }
        }

        .form-buttons{
            width: 100%;
            margin-top: 2vh;
            display: flex;
            justify-content: flex-end;

            .btn-main{
                &::after{
                    content: 'Submit';
                }
            }
        }

        .form-item ~ .form-item ~ .form-item{
            margin-top: 2vh;
        }
    }
}

@media only screen and (max-width: 767px){
    .content-contact{
        height: fit-content;

        .text{
            width: 100%;
        }

        .form{
            width: 100%;

            .form-item{
                width: 100%;
            }

            .form-item ~ .form-item{
                margin-top: 2vh;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
    .content-contact{
        flex-grow: 1;
    }
}