@import 'vars.scss';

//Elements
.btn-main{
    position: relative;
    padding: 10px 40px;
    background: transparent;
    border: 1px solid $color-main;
    box-shadow: 0px 0px 20px 0px $color-main;
    color: transparent;
    font-size: 19px;
    font-family: 'Lora';
    font-weight: bold;

    &::before{
        content: '';
        position: absolute;
        width: 0%; height: 100%;
        top: 0px; left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(122deg, rgba(28,88,184,1) 0%, rgba(255,20,147,1) 56%, rgba(255,0,95,1) 100%);
        transition: width .15s ease-in;
    }

    &::after{
        content: 'Contact';
        position: absolute;
        width: 100%; height: 100%;
        top: 0px; left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-main;
        transition: color .15s ease-in;
    }

    &:hover{
        cursor: pointer;

        &::before{
            width: 100%;
        }

        &::after{
            color: white;
        }
    }
}

.input{
    width: 100%;
    padding: 5px 10px;
    background-color: rgba($color: white, $alpha: .5);
    border: 1px solid transparent;
    border-radius: 10px;
    color: white;
    font-size: 19px;
    font-family: 'Lora';
    outline: none;
    transition: border .15s ease-in, box-shadow .15s ease-in;

    &:hover, &:focus{
        border: 1px solid $color-main;
        box-shadow: 0px 0px 15px 0px $color-main;
    }

    &::placeholder{
        color: #666666;
    }
}

//Texts
.text-navbar{
    font-size: 30px;
    font-family: 'Playfair Display';
    font-weight: bold;
}

.text-headline{
    font-size: 35px;
    font-family: 'Playfair Display';
}

.text-normal{
    font-size: 19px;
    font-family: 'Lora';
}

@media only screen and (max-width: 767px){
    
}

@media only screen and (min-width: 768px) and (max-width: 1024px){

}