@import 'vars.scss';

.content-about{
    position: relative;
    width: 100%; height: 100%;
    padding: 2.5%;
    display: flex;
    justify-content: space-around;
    background-color: rgba($color: white, $alpha: .25);
    border: 1px solid $color-main;
    box-shadow: 0px 0px 25px 0px $color-main;
    overflow: hidden;
    z-index: 1;

    &::before{
        content: '';
        position: absolute;
        width: 100%; height: 100%;
        top: 0px; left: 0px;
        background: linear-gradient(122deg, rgba(28,88,184,1) 0%, rgba(255,20,147,1) 56%, rgba(255,0,95,1) 100%);
        filter: blur(40px) brightness(.6);
        z-index: -1;
    }

    .about-myself,
    .about-mywork{
        width: 45%;
        
        h1{
            text-align: center;
        }

        p{
            margin-top: .5vh;
        }
    }

    .about-mywork{
        p:nth-child(3){
            margin-top: 3vh;
            font-weight: bold;
        }

        ul{
            margin-top: .5vh;
            margin-left: 5%;
            list-style: square;

            li{
                margin-top: .5vh;
            }
        }
    }
}

@media only screen and (max-width: 767px){
    .content-about{
        height: fit-content;
        flex-direction: column;
        justify-content: unset;
        align-items: center;

        .about-myself,
        .about-mywork{
            width: 95%;
            text-align: center;
        }

        .about-mywork{
            margin-top: 7vh;

            ul{
                text-align: left;
                margin-left: 10%;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
    .content-about{
        .about-mywork{
            ul{
                margin-left: 10%;
            }
        }
    }
}