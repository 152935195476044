@import 'vars.scss';

@keyframes animate-logo{
    0%{
        stroke-dashoffset: 900;
        fill: none;
    }
    25%{
        stroke-dashoffset: 0;
        fill: $color-main;
    }
    50%{
        stroke-dashoffset: 0;
        fill: $color-main;
    }
    75%{
        stroke-dashoffset: 900;
        fill: none;
    }
    100%{
        stroke-dashoffset: 900;
        fill: none;
    }
}

.homedarkpage{
    width: 100vw; height: 100vh;
    background-color: $color-background;
    display: flex;
    color: white;

    .navbar{
        width: 15%; height: 100%;
        border: 0px solid $color-main;
        border-width: 0px 1px 0px 0px;
        box-shadow: 0px 0px 20px 0px $color-main;

        .logo{
            width: 100%;
            padding: 15% 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $color-main;
            text-shadow: 0px 0px 10px $color-main;

            svg{
                transform: scale(.75) scaleX(.8) rotate(7.5deg);
                filter: drop-shadow(0px 0px 10px $color-main);
            }

            p{
                margin-top: .5vh;
                font-family: 'Playfair Display';
                font-size: 20px;
                font-weight: bold;
                text-align: center;
            }
        }

        .menu{
            margin-top: 40%;
            padding-left: 10%;

            li{
                margin-top: 15%;
                display: flex;
                transition: color .15s ease-in;

                i{
                    width: 25%;
                    margin-right: 2.5%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: color .15s ease-in, text-shadow .2s ease-in;

                    &.active{
                        color: $color-main;
                        text-shadow: 0px 0px 15px $color-main;
                    }
                }

                &:hover{
                    cursor: pointer;

                    i{
                        color: $color-main;
                        text-shadow: 0px 0px 15px $color-main;
                    }
                }

                &.active{
                    color: $color-main;
                }
            }
        }
    }

    .wrapper{
        width: 85%; height: 100%;

        .mobile-navbar{
            position: fixed;
            width: 100%; height: 5vh;
            display: none;
            background-color: $color-background;
            border: 1px solid $color-main;
            border-width: 0px 0px 1px 0px;
            box-shadow: 0px 0px 15px 0px $color-main;
            z-index: 2;
            transition: height .3s ease-in, border-width .3s ease-in;
            transition-delay: .15s;

            .logo{
                width: 100%; height: 30%;
                padding: 10% 25%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                opacity: 0;
                color: $color-main;
                text-shadow: 0px 0px 10px $color-main;
                transition: opacity .15s ease-in;
                transition-delay: 0s;
    
                svg{
                    transform: scale(.75) scaleX(.8) rotate(7.5deg);
                    filter: drop-shadow(0px 0px 10px $color-main);
                }
    
                p{
                    margin-top: .5vh;
                    font-family: 'Playfair Display';
                    font-size: 20px;
                    font-weight: bold;
                    text-align: center;
                }
            }

            .menu{
                opacity: 0;
                transition: opacity .15s ease-in;
                transition-delay: 0s;
    
                li{
                    margin-top: 5%;
                    display: flex;
                    transition: color .15s ease-in;
    
                    i{
                        width: 25%;
                        margin-right: 2.5%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: color .15s ease-in, text-shadow .2s ease-in;
    
                        &.active{
                            color: $color-main;
                            text-shadow: 0px 0px 15px $color-main;
                        }
                    }
    
                    &:hover{
                        cursor: pointer;
    
                        i{
                            color: $color-main;
                            text-shadow: 0px 0px 15px $color-main;
                        }
                    }
    
                    &.active{
                        color: $color-main;
                    }
                }
            }

            .socials{
                position: absolute;
                width: 100%;
                bottom: 10vh;
                display: flex;
                justify-content: space-around;
                align-items: center;
                opacity: 0;
                transition: opacity .15s ease-in;
                transition-delay: 0s;
    
                i{
                    color: $color-main;
                    font-size: 25px;
                    text-shadow: 0px 0px 15px $color-main;
                    transition: all .15s ease-in;

                    &:hover{
                        text-shadow: 0px 0px 25px $color-main;
                        cursor: pointer;
                        transform: scale(1.2);
                    }
                }
            }
            
            .button{
                position: absolute;
                bottom: 0px;
                width: 100%; height: 5vh;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 0px solid $color-main;
                transition: border-width .15s ease-in;

                i{
                    opacity: 1;
                    font-size: 25px;
                    transition: opacity .15s ease-in;

                    &.hide{
                        opacity: 0;
                    }
                }
            }

            &.active{
                height: 100vh;
                transition-delay: 0s;
                border-width: 0px;

                .logo{
                    opacity: 1;
                    transition-delay: .3s;
                }

                .menu{
                    opacity: 1;
                    transition-delay: .3s;
                }

                .socials{
                    opacity: 1;
                    transition-delay: .3s;
                }

                .button{
                    border-width: 1px 0px 0px 0px;
                    transition-delay: .3s;
                }
            }

            &.hide{
                .logo{
                    visibility: hidden;
                }

                .menu{
                    visibility: hidden;
                }

                .socials{
                    visibility: hidden;
                }
            }
        }

        .topbar{
            width: 100%; height: 7.5%;
            padding: 0% 1.5%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
    
            .socials{
                width: 25%;
                margin-left: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                i{
                    color: $color-main;
                    font-size: 25px;
                    text-shadow: 0px 0px 15px $color-main;
                    transition: all .15s ease-in;

                    &:hover{
                        text-shadow: 0px 0px 25px $color-main;
                        cursor: pointer;
                        transform: scale(1.2);
                    }
                }
            }
    
            a{
                margin-left: auto;
            }
        }

        .content{
            width: 100%; height: 92.5%;
            padding: 1% 4% 2.5% 4%;
            box-sizing: border-box;

            .content-home{
                position: relative;
                width: 100%; height: 100%;
                padding: 5%;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: rgba($color: white, $alpha: .25);
                border: 1px solid $color-main;
                box-shadow: 0px 0px 25px 0px $color-main;
                overflow: hidden;
                z-index: 1;

                &::before{
                    content: '';
                    position: absolute;
                    width: 100%; height: 100%;
                    top: 0px; left: 0px;
                    background: linear-gradient(122deg, rgba(28,88,184,1) 0%, rgba(255,20,147,1) 56%, rgba(255,0,95,1) 100%);
                    filter: blur(40px) brightness(.6);
                    z-index: -1;
                }

                .logo{
                    width: 30%;
                    padding: 1%;
                    background-color: rgba($color: #ffffff, $alpha: .15);
                    border-radius: 15px;
                    box-shadow: inset 9.91px 9.91px 15px rgba($color: #E8E8E8, $alpha: .15), 
                                inset -9.91px -9.91px 15px rgba($color: #FFFFFF, $alpha: .15);

                    svg{
                        transform: scale(.75) scaleX(.8) rotate(7.5deg);
                        filter: drop-shadow(0px 0px 10px $color-main);

                        path{
                            stroke-dashoffset: 900;
                            stroke-dasharray: 900;
                            animation: animate-logo 15s linear infinite;

                        }
                    }

                    p{
                        margin-top: -7.5%;
                        color: $color-main;
                        font-family: 'Playfair Display';
                        font-size: 35px;
                        font-weight: bold;
                        text-align: center;
                        text-shadow: 0px 0px 10px $color-main;
                    }
                }

                .text{
                    margin-top: 3vh;
                    text-align: center;

                    h1{
                        font-size: 55px;
                    }

                    p{
                        margin-top: .5vh;
                        font-size: 25px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px){
    .homedarkpage{
        height: auto; min-height: 100vh;

        .navbar{
            display: none;
        }

        .wrapper{
            width: 100%; height: auto;

            .mobile-navbar{
                display: block;
            }

            .topbar{
                display: none;
            }

            .content{
                min-height: 95vh;
                margin-top: 5vh;
                padding: 3vh 4% 3vh 4%;

                .content-home{
                    min-height: 100%;

                    .logo{
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
    .homedarkpage{
        height: auto; min-height: 100vh;

        .navbar{
            display: none;
        }

        .wrapper{
            width: 100%; height: auto; min-height: 100vh;
            display: flex;
            flex-direction: column;

            .mobile-navbar{
                height: 4vh;
                display: block;

                .button{
                    height: 4vh;
                }
            }

            .topbar{
                display: none;
            }

            .content{
                margin-top: 6vh;
                flex-direction: column;
                flex: 1;
                
                .content-home{
                    min-height: 100%; height: 100%;
                    flex-grow: 1;

                    .logo{
                        width: 50%;
                    }
                }
            }
        }
    }
}