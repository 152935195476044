/*Standards*/
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body, html{
    width: calc(100vw - (100vw - 100%));
    min-height: 100vh;
}

ul{
    list-style: none;
}

a{
    color: inherit;
    text-decoration: none;
}

.clear{
    clear: both;
}