.startuppage{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;

    .ui-split{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .content{
            display: flex;
            flex-direction: column;
            align-items: center;

            .btn{
                padding: 10px 40px;
                margin-top: 1vh;
                background-color: transparent;
                border: 1px solid black;
                font-size: 16px;

                &:hover{
                    cursor: pointer;
                }
            }
        }

        &.light{
            background-color: wheat;
        }

        &.dark{
            background-color: #777777
        }
    }
}